.bootstrap-table-extended {
	.search-col {
		input.search {
			width: 350px;
		}
	}

	@include media-breakpoint-down(sm) {
		.search-col,
		.pagination-col {
			text-align: center;
			margin-bottom: 5px;
		}
	}
	.preview-listitem {
		max-height: 30px;
	}
}

table th {
	&.reorder-column-left {
		border-left: 3px solid #666;
		background-color: #efefef;
	}
	&.reorder-column-right {
		border-right: 3px solid #666;
		background-color: #efefef;
	}
}
