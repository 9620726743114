.edit-mode {
	.read-control { display: none; }
	.edit-control {
		display: block;

		&.checkbox-inline,
		&.radio-inline {
			display: inline-block;
		}
	}

}
.read-mode {
	.read-control { display: block; }
	.edit-control { display: none; }
}

@media (min-width: 768px) {
	.form-inline {
		.edit-mode {
			.edit-control { display: inline-block; }
		}
		.read-mode {
			.read-control { display: inline-block; }
		}
	}
}
